import React from 'react';
import { CaseStudyItem, CaseStudyItemProps } from '../../components/CaseStudyItem/CaseStudyItem';
import { CaseStudyTechnology } from '../CaseStudyTechnology/CaseStudyTechnology';

import './CaseStudyContent.scss';

export type ProjectContentProps = {
    contentItems: CaseStudyItemProps[];
    technologies: { image: string; id: string }[];
    technologyDescription: string;
};

export const CaseStudyContent = ({
    contentItems,
    technologies,
    technologyDescription,
}: ProjectContentProps) => (
    <section className="case-study-content">
        <div className="case-study-content__wrapper">
            {contentItems?.map(contentItem => {
                return (
                    <CaseStudyItem
                        key={contentItem.id}
                        title={contentItem.title}
                        image1={contentItem.image1 ? contentItem.image1 : undefined}
                        image2={contentItem.image2 ? contentItem.image2 : undefined}
                        description={contentItem.description}
                        id={contentItem.id}
                    />
                );
            })}
            <CaseStudyTechnology technologies={technologies} description={technologyDescription} />
        </div>
    </section>
);
