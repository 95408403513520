import React from 'react';
import Key from '../../assets/images/key.svg';

import './CaseStudyResults.scss';

export const CaseStudyResults = () => (
    <section className="case-study-results">
        <div className="case-study-results__background" aria-hidden="true" />
        <div className="case-study-results__wrapper">
            <div className="case-study-results--left">
                <div>
                    <h2 className="case-study-results__title heading-3">Results</h2>
                </div>
                <div className="case-study-results__text">
                    <p className="case-study-results__highlight">
                        We delivered a website that, in addition to its refinement and simplicity,
                        reinforces the values and concepts of the brand.
                    </p>
                    <p className="case-study-results__description">
                        Easy to access, dynamic and agile, a website that met the needs of the
                        client and that its users will enjoy browsing. Go ahead! We invite you to
                        discover a site with a narrative like no other.
                    </p>
                </div>
            </div>
            <div className="case-study-results--left">
                <div className="case-study-results__key-container">
                    <img
                        src={Key}
                        alt="{=}"
                        className="key case-study-results__key pulse"
                        aria-hidden="true"
                    />
                </div>
            </div>
        </div>
    </section>
);
