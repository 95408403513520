import React, { useEffect, useRef, useState } from 'react';
import Rarrow from '../../assets/icons/shape_arrow.svg';
import gsap from 'gsap';
import AnimatedImage from '../AnimatedImage/AnimatedImage';

import './CaseStudyItem.scss';

export type CaseStudyItemProps = {
    title: string;
    image1?: string;
    image2?: string;
    description: string;
    id: string;
};

export const CaseStudyItem = ({ title, image1, image2, description, id }: CaseStudyItemProps) => {
    const [isUnderView, setIsUnderView] = useState(false);
    const ItemContentRef = useRef<HTMLDivElement>(null);
    const ItemImagesRef = useRef<HTMLDivElement>(null);

    const initTimelines = () => {
        if (!ItemContentRef.current) return;
        const startViewport = (window.innerHeight * 3) / 4;

        gsap.timeline({
            scrollTrigger: {
                trigger: `.case-study-item__wrapper--${id} .case-study-item__content`,
                start: `${-(ItemContentRef.current.clientHeight * 0.6)} ${startViewport}`,
                scrub: 1,
                onEnter: () => {
                    ItemContentRef.current?.classList.add('animation-play');
                },
            },
        });

        if (!ItemImagesRef.current) return;

        gsap.timeline({
            scrollTrigger: {
                trigger: `.case-study-item__wrapper--${id} .case-study-item__images`,
                start: `${-(ItemImagesRef.current.clientHeight * 0.6)} ${startViewport}`,
                scrub: 1,
                onEnter: () => {
                    ItemImagesRef.current?.classList.add('animation-play');
                    setIsUnderView(true);
                },
            },
        });
    };

    useEffect(() => {
        initTimelines();
    }, []);

    return (
        <section className="case-study-item">
            <div className={`case-study-item__wrapper case-study-item__wrapper--${id}`}>
                <div
                    className={`case-study-item__content animated fadeInUp ${
                        image1 || image2 ? 'case-study-item__content--with-images' : null
                    }`}
                    ref={ItemContentRef}>
                    <div className="case-study-item__heading">
                        <h2 className="case-study-item__title">{title}</h2>
                        <div className="case-study-item__arrow-wrapper">
                            <span className="case-study-item__slash">/</span>
                            <img src={Rarrow} alt="arrow" className="case-study-item__arrow" />
                        </div>
                    </div>
                    <p className="case-study-item__description">{description}</p>
                </div>
                {image1 || image2 ? (
                    <div
                        className={`case-study-item__images animated fadeInUp ${
                            image2 ? 'case-study-item__images--2-images' : ''
                        }`}
                        ref={ItemImagesRef}>
                        {image1 ? (
                            <div className="case-study-item__img-container--1">
                                <AnimatedImage
                                    imageAlt="partner1"
                                    imageUrl={image1}
                                    isActive={isUnderView}
                                />
                            </div>
                        ) : null}
                        {image2 ? (
                            <div className="case-study-item__img-container--2">
                                <AnimatedImage
                                    imageAlt="partner1"
                                    imageUrl={image2}
                                    isActive={isUnderView}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </section>
    );
};
