import React, { useEffect, useRef } from 'react';
import { Media } from '../../components/Media/Media';
import gsap from 'gsap';

import './CaseStudyTechnology.scss';

type CaseStudyTechnologyProps = {
    technologies: { image: string; id: string }[];
    description: string;
};

export const CaseStudyTechnology = ({ technologies, description }: CaseStudyTechnologyProps) => {
    const technologyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!technologyRef.current) return;
        const startViewport = (window.innerHeight * 3) / 4;
        const startTrigger = -(technologyRef.current?.clientHeight * 0.6);

        gsap.timeline({
            scrollTrigger: {
                trigger: `.case-study-technology`,
                start: `${startTrigger} ${startViewport}`,
                scrub: 1,
                onEnter: () => {
                    technologyRef.current?.classList.add('animation-play');
                },
            },
        });
    }, []);

    return (
        <section className="case-study-technology animated fadeInUp" ref={technologyRef}>
            <div className="case-study-technology__wrapper">
                <h2 className="heading-3 case-study-technology__title">Technology</h2>
                <div className="case-study-technology__description">
                    {
                        <div className="case-study-technology__technologies">
                            {technologies.map(item => (
                                <Media
                                    key={item.id}
                                    className="case-study-technology__technology-logo"
                                    source={item.image}
                                    alt="logo"
                                    noPicture={true}
                                />
                            ))}
                        </div>
                    }
                    <p className="case-study-technology__description-text">{description}</p>
                </div>
            </div>
        </section>
    );
};
